import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { ContentLayout, PageLayout } from 'layout'
import { UserContext } from 'contexts'
import { MenuTitles } from 'constants/menu'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  GetAllAgencies,
  GetRealestateCode,
  UpdateRealestateCode,
  UpdateDomainCode,
  UpdateAgencyRexId,
  GetDomainCode,
  GetAgencyRexId,
  GetCampaignFlowId,
  UpdateCampaignFlowId,
} from 'services'
import { Realestate } from 'types/realestate'
import { AgxToastState } from 'types/commonTypes'
import {
  AgxRow,
  AgxColumn,
  AgxHeader,
  AgxButton,
  AgxLabel,
  AgxTextInput,
  AgxDivider,
  AgxToast,
  Images,
} from '@urbanx/agx-ui-components'
import { Domain } from 'types/domain'
import './integrations.scss'
import { Agency } from 'types/agency'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { FormPrompt } from 'components/FormPrompt'
import { CampaignFlow } from 'types/campaignFlow'

interface AgencyCodeInfo {
  newCode: string | null
  oldCode: string | null
  isLoading: boolean
  saved: boolean
}

const IntegrationsPage = () => {
  const { agencyId } = useParams()
  const queryClient = useQueryClient()
  const user = useContext(UserContext)
  const [isFormDirty, setIsFormDirty] = useState(false)

  const [, getAuthToken] = useAzureAuth()

  const { data: agencies } = useQuery<Agency[] | undefined>({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
  })

  const rexKey = [`rex-code-${agencyId}`, agencyId]

  enum AgencyCodes {
    Rea = 'REA Agency Code',
    Domain = 'Domain Agency Code',
    Rex = 'Rex Agency ID',
    CampaignFlow = 'Campaign Flow ID',
  }

  const regexMap = {
    [AgencyCodes.Rea]: /^[A-Z]{6}$/,
    [AgencyCodes.Domain]: /^\d{5}$/,
    [AgencyCodes.Rex]: /^\d{4}$/,
    [AgencyCodes.CampaignFlow]: /^\d{3,8}$/,
  }

  const {
    data: rex,
    isLoading: rexIsLoading,
    refetch: refetchRexIdOnPageLoad,
  } = useQuery<number | undefined>({
    queryKey: rexKey,
    queryFn: (queryInfo) => GetAgencyRexId(queryInfo, getAuthToken),
    onError: (error) => handleError('rex', error),
  })

  const agency = useMemo(
    () => agencies?.find((agency) => agency.id === agencyId),
    [agencies, agencyId]
  )

  const [realestateInfo, setRealestateInfo] = useState<AgencyCodeInfo>({
    newCode: null,
    oldCode: '',
    isLoading: false,
    saved: true,
  })
  const [domainInfo, setDomainInfo] = useState<AgencyCodeInfo>({
    newCode: null,
    oldCode: '',
    isLoading: false,
    saved: true,
  })
  const [rexInfo, setRexInfo] = useState<AgencyCodeInfo>({
    newCode: null,
    oldCode: '',
    isLoading: false,
    saved: true,
  })
  const [campaignFlowInfo, setCampaignFlowInfo] = useState<AgencyCodeInfo>({
    newCode: null,
    oldCode: '',
    isLoading: false,
    saved: true,
  })
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })

  const realestateKey = [`realestate-code-${agencyId}`, agencyId]

  const { data: realestate, isLoading: realestateIsLoading } = useQuery<
    Realestate | undefined
  >({
    queryKey: realestateKey,
    queryFn: (queryInfo) => GetRealestateCode(queryInfo, getAuthToken),
    onError: (error) => handleError('realestate', error),
  })

  const domainKey = [`domain-code-${agencyId}`, agencyId]

  const { data: domain, isLoading: domainIsLoading } = useQuery<
    Domain | undefined
  >({
    queryKey: domainKey,
    queryFn: (queryInfo) => GetDomainCode(queryInfo, getAuthToken),
    onError: (error) => handleError('domain', error),
  })

  const campaignFlowKey = [`campain-flow-id-${agencyId}`, agencyId]

  const { data: campaignFlow, isLoading: campaignFlowIsLoading } = useQuery<
    CampaignFlow | undefined
  >({
    queryKey: campaignFlowKey,
    queryFn: (queryInfo) => GetCampaignFlowId(queryInfo, getAuthToken),
    onError: (error) => handleError('campaign flow', error),
  })

  // Mutations Section
  const { mutate: updateRealestateCode } = useMutation(UpdateRealestateCode, {
    onSuccess: () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: realestateKey,
        }),
      ])
      updateToastState({
        color: 'success',
        message: 'REA agency code updated successfully',
        open: true,
      })
      setRealestateInfo({ ...realestateInfo, saved: true })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error updating the REA agency code',
        open: true,
      })
    },
  })

  const { mutate: updateDomainCode } = useMutation(UpdateDomainCode, {
    onSuccess: () => {
      setIsFormDirty(false)
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: domainKey,
        }),
      ])
      updateToastState({
        color: 'success',
        message: 'Agency Domain code updated successfully',
        open: true,
      })
      setDomainInfo({ ...domainInfo, saved: true })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message:
          'Error updating the Domain agency code. Please ensure the agency code is less than 2,147,483,647',
        open: true,
      })
    },
  })

  const { mutate: updateAgencyRexId } = useMutation(UpdateAgencyRexId, {
    onSuccess: () => {
      setIsFormDirty(false)
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: rexKey,
        }),
      ])
      updateToastState({
        color: 'success',
        message: 'Rex Agency code updated successfully',
        open: true,
      })
      setRexInfo({ ...rexInfo, saved: true })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error updating the Rex Agency code.',
        open: true,
      })
    },
  })

  const { mutate: updateCampaignFlowId } = useMutation(UpdateCampaignFlowId, {
    onSuccess: () => {
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: campaignFlowKey,
        }),
      ])
      updateToastState({
        color: 'success',
        message: 'Campaign Flow Agency ID updated successfully',
        open: true,
      })
      setRexInfo({ ...rexInfo, saved: true })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: 'Error updating Campaign Flow Agency ID',
        open: true,
      })
    },
  })

  // Saving Section
  const saveRealestateCodeChanges = () => {
    updateRealestateCode({
      AgencyId: agencyId || '',
      Code: realestateInfo.newCode,
      getAuthToken,
    })
  }

  const saveDomainCodeChanges = () => {
    updateDomainCode({
      AgencyId: agencyId || '',
      Code: parseInt(domainInfo.newCode || ''),
      getAuthToken,
    })
  }

  const saveAgencyRexIdChanges = () => {
    updateAgencyRexId({
      AgencyId: agencyId || '',
      RexId: parseInt(rexInfo.newCode || ''),
      getAuthToken,
    })
  }

  const saveCampaignFlowIdChanges = () => {
    updateCampaignFlowId({
      AgencyId: agencyId || '',
      Code: parseInt(campaignFlowInfo.newCode || ''),
      getAuthToken,
    })
  }

  // UseEffect Section
  useEffect(() => {
    setIsFormDirty(false)
    setRealestateInfo({
      ...realestateInfo,
      newCode: realestate?.code || null,
      oldCode: realestate?.code || '',
    })
  }, [realestate])
  useEffect(() => {
    setIsFormDirty(false)
    setDomainInfo({
      ...domainInfo,
      newCode: domain?.code || null,
      oldCode: domain?.code || '',
    })
  }, [domain])
  useEffect(() => {
    setIsFormDirty(false)
    setRexInfo({
      ...rexInfo,
      newCode: rex?.toString() || null,
      oldCode: rex?.toString() || '',
    })
  }, [rex])
  useEffect(() => {
    setIsFormDirty(false)
    setCampaignFlowInfo({
      ...campaignFlowInfo,
      newCode: campaignFlow?.code || null,
      oldCode: campaignFlow?.code || '',
    })
  }, [campaignFlow])
  useEffect(() => {
    refetchRexIdOnPageLoad()
  }, [])

  // Element Rendering Section
  const renderAgencyCodeElements = (
    title: AgencyCodes,
    codeInfo: AgencyCodeInfo,
    stateFunction: Dispatch<SetStateAction<AgencyCodeInfo>>,
    updateFunction: () => void
  ) => {
    let textInput = (
      <AgxTextInput
        id={`input-${title}`}
        noOptionalLabel
        readonly={true}
        defaultValue="Loading..."
      />
    )

    if (!codeInfo.isLoading && codeInfo.oldCode !== null) {
      textInput = (
        <AgxTextInput
          id={`input-${title}`}
          key={title}
          noOptionalLabel
          defaultValue={codeInfo.oldCode}
          parentControlValue={true}
          onInputValueChange={(info: { value: any }) => {
            if (info.value && codeInfo.oldCode !== info.value) {
              setIsFormDirty(true)
            } else {
              setIsFormDirty(false)
            }
            stateFunction({ ...codeInfo, newCode: info.value, saved: false })
          }}
        />
      )
    }

    let updaterElement = null

    if (Object.values(AgencyCodes).includes(title as AgencyCodes)) {
      updaterElement = (
        <AgxButton
          text="Save Changes"
          medium
          primary
          disabled={
            codeInfo.newCode === codeInfo.oldCode ||
            codeInfo.newCode === null ||
            !regexMap[title as AgencyCodes]?.test(codeInfo.newCode)
          }
          onClick={() => updateFunction()}
        />
      )
    } else if (codeInfo.saved) {
      updaterElement = (
        <AgxRow centered mediumGap>
          <Images.Checkmark />
          <AgxLabel>Changes Saved</AgxLabel>
        </AgxRow>
      )
    }

    return (
      <AgxColumn
        extraLargeGap
        extraClasses="companyDetailsPanel container50Percent"
      >
        <AgxRow spaceBetween extraClasses={'agency-codes-individual-headers'}>
          <AgxHeader size={5}>{title}</AgxHeader>
          {updaterElement}
        </AgxRow>
        <AgxDivider />
        <AgxRow veryLargeGap>{textInput}</AgxRow>
      </AgxColumn>
    )
  }

  // Error Handling Section
  const handleError = (name: string, error: any) => {
    console.error(`${name} Agency Code Update Errors`)
    console.error(error)
  }

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={agency ? agency.name : ''}
      currentPageTitle={MenuTitles.INTEGRATIONS}
    >
      <ContentLayout hasSideMenu={true} activeMenu={MenuTitles.INTEGRATIONS}>
        <FormPrompt hasUnsavedChanges={isFormDirty} />
        <AgxToast selector="#agxToast" toastState={toastState} />
        <AgxColumn veryLargeGap>
          <AgxHeader size={5}>Agency Codes</AgxHeader>
          <AgxDivider />
          <AgxColumn extraLargeGap>
            {renderAgencyCodeElements(
              AgencyCodes.Rea,
              { ...realestateInfo, isLoading: realestateIsLoading },
              setRealestateInfo,
              saveRealestateCodeChanges
            )}
            {renderAgencyCodeElements(
              AgencyCodes.Domain,
              { ...domainInfo, isLoading: domainIsLoading },
              setDomainInfo,
              saveDomainCodeChanges
            )}
            {renderAgencyCodeElements(
              AgencyCodes.Rex,
              { ...rexInfo, isLoading: rexIsLoading },
              setRexInfo,
              saveAgencyRexIdChanges
            )}
            {renderAgencyCodeElements(
              AgencyCodes.CampaignFlow,
              { ...campaignFlowInfo, isLoading: campaignFlowIsLoading },
              setCampaignFlowInfo,
              saveCampaignFlowIdChanges
            )}
          </AgxColumn>
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default IntegrationsPage
